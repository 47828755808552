define([
        'modules/upx/models/model',
        './structs/Newsletter'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'CommerceModule',

            object: 'Newsletter',
            syncNewsletter: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncNewsletter',parameters, ajaxOptions);
            },
            read: function (parameters, ajaxOptions) {
                return this.callObjectMethod('readNewsletter',parameters, ajaxOptions);
            },
            countUnread: function (parameters, ajaxOptions) {
                return this.callObjectMethod('countUnreadNewsletters',parameters, ajaxOptions);
            },
            readAll: function (parameters, ajaxOptions) {
                return this.callObjectMethod('readAllNewsletters',parameters, ajaxOptions);
            }
        });
    }
);