define([
        'modules/upx/collections/collection',
        '../models/Invoice',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'BillingModule',
            object: 'Invoice',
            collection_method: 'listInvoices',
            model: Model
        });
    }
);