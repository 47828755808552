define([
        'modules/upx/models/model',
        './structs/Session'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ManagementModule',

            object: 'Session',
            start: function (parameters, ajaxOptions) {
                return this.callObjectMethod('startSession',parameters, ajaxOptions);
            },
            startSubuser: function (parameters, ajaxOptions) {
                return this.callObjectMethod('startSubuserSession',parameters, ajaxOptions);
            },
            refresh: function (parameters, ajaxOptions) {
                return this.callObjectMethod('refreshSession',parameters, ajaxOptions);
            },
            refreshWithLongExpiry: function (parameters, ajaxOptions) {
                return this.callObjectMethod('refreshSessionWithLongExpiry',parameters, ajaxOptions);
            },
            end: function (parameters, ajaxOptions) {
                return this.callObjectMethod('endSession',parameters, ajaxOptions);
            }
        });
    }
);