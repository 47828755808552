define([
        'modules/upx/collections/collection',
        '../models/Newsletter',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'CommerceModule',
            object: 'Newsletter',
            collection_method: 'listNewsletters',
            model: Model
        });
    }
);