define([
        'modules/upx/models/model',
        './structs/Feature'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ManagementModule',

            object: 'Feature',
            runMetric: function (parameters, ajaxOptions) {
                return this.callObjectMethod('runFeatureMetric',parameters, ajaxOptions);
            },
            changeStatus: function (parameters, ajaxOptions) {
                return this.callObjectMethod('changeFeatureStatus',parameters, ajaxOptions);
            }
        });
    }
);